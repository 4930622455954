import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";

import Layout from "../../components/layout";
import {
  char2Int,
  durationToWPM,
  int2Char,
} from "../../projects/ml-keyboard/utils";
import { useMlKeyboard } from "../../projects/ml-keyboard/use-ml-keyboard";
import {
  CircularProgress,
  Typography,
  Link as MuiLink,
} from "@material-ui/core";
import { BigramMosaic } from "../../projects/ml-keyboard/bigram-mosaic";
import { Link as GatsbyLink } from "gatsby";

const spaceInt = char2Int(" ");
const mapKeyToAlt = { [spaceInt]: "·", 0: " " };

const Letters = styled.h1`
  font-family: monospace;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  transition: height 0.3s ease, opacity 0.3s ease;
  height: ${(p) => (p.index === 0 || p.index === 4 ? 0 : "1.5em")};
  opacity: ${(p) => (p.index === 2 ? 1 : 0.1)};
`;

const Letter = styled.span`
  position: relative;
  opacity: ${(p) => (p.prev ? 0.3 : 1)};
  transition: opacity 0.3s ease;
  ::after {
    opacity: ${(p) => (p.current ? 1 : 0)};
    position: absolute;
    content: "🔺";
    font-size: 0.4em;
    text-align: center;
    bottom: -1ch;
    left: -0.5ch;
    right: -0.5ch;
  }
`;

const Input = styled.input.attrs({
  value: "",
  placeholder: "CLICK TO ACTIVATE",
  autoCapitalize: "none",
  autoCorrect: "false",
})`
  resize: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.87;
  z-index: 1;
  cursor: default;
  border: none;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  margin: 0;
  background-color: ${(p) => p.theme.palette.primary.main};
  text-align: center;
  transition: box-shadow 0.1s ease;
  ::placeholder {
    color: ${(p) => p.theme.palette.primary.contrastText};
    ${(p) => p.theme.typography.h5}
  }
  :focus {
    background-color: transparent;
    cursor: none;
    color: transparent;
    box-shadow: ${(p) => p.theme.shadows[8]};
    outline: none;
    ::placeholder {
      color: transparent;
    }
  }
`;

const WpmNumber = styled(Typography).attrs({
  variant: "h1",
})`
  margin: 0;
  color: ${(p) => p.theme.palette.text.secondary};
`;

const WpmLabel = styled(Typography).attrs({
  variant: "h6",
})`
  margin: 0;
  color: ${(p) => p.theme.palette.text.hint};
`;

const WpmContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 8rem;
  @media (max-width: 600px) {
    display: none;
  }
`;

const DisplayConainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputContainer = styled.div`
  margin: 1rem 0;
  transform: translateZ(0);
  font-size: 100%;
  @media (max-width: 800px) {
    font-size: 2vw;
  }
`;

const LayoutWrapper = ({ children }) => (
  <Layout
    title="RNN Typing Practice"
    keywords={[
      "recurrent neural network",
      "neural network",
      "gated recurrent neural network",
      "GRU",
      "RNN",
      "typing",
      "practice",
      "keyboard",
      "training",
    ]}
    description="Using recurrent neural networks to help you practice typing"
  >
    {children}
    Want to practice with real words? Try{" "}
    <MuiLink component={GatsbyLink} to="/projects/faster-phalanges">
      Faster Phalanges
    </MuiLink>
  </Layout>
);

const MlKeyboardPage = () => {
  const keyboardLoggerRef = useRef(null);

  const {
    linesOfText,
    normalizedKeyMatrix,
    handleKeypress,
    cursor,
    mean,
  } = useMlKeyboard();

  useEffect(() => {
    const { current = null } = keyboardLoggerRef;
    if (current === null) return;
    current.focus();
  }, [keyboardLoggerRef]);

  if ([linesOfText, normalizedKeyMatrix].includes(null)) {
    return (
      <LayoutWrapper>
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size="3rem" />
        </Box>
      </LayoutWrapper>
    );
  }

  return (
    <LayoutWrapper>
      <DisplayConainer>
        <BigramMosaic bigrams={normalizedKeyMatrix} />
        <WpmContainer>
          <WpmNumber>{durationToWPM(mean).toFixed(2)} </WpmNumber>
          <WpmLabel>
            <abbr title="Words Per Minute">WPM</abbr>
          </WpmLabel>
        </WpmContainer>
      </DisplayConainer>
      {linesOfText.length >= 3 ? (
        <InputContainer>
          <Input
            ref={keyboardLoggerRef}
            onChange={(e) => handleKeypress(e.target.value.toLowerCase())}
          />
          {linesOfText.map(({ time, keys }, lineIndex) => (
            <Letters key={time} index={lineIndex}>
              {keys.map(({ timeStamp, charInt }, charIndex) => (
                <Letter
                  key={timeStamp}
                  current={lineIndex === 2 && cursor === charIndex}
                  prev={lineIndex === 2 && cursor > charIndex}
                >
                  {mapKeyToAlt[charInt] || int2Char(charInt)}
                </Letter>
              ))}
            </Letters>
          ))}
        </InputContainer>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin="1rem"
        >
          <CircularProgress size="3rem" />
        </Box>
      )}
    </LayoutWrapper>
  );
};

export default MlKeyboardPage;
