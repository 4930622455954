import { useEffect, useReducer } from "react";
import MlKeyboardWorker from "../ml-keyboard.worker";
import actionTypes from "../ml-keyboard-action-types.json";
import { LOCAL_STORAGE_KEY, stateReducers } from "./state-reducers";
import { initialState } from "./initial-state";

export const useMlKeyboard = () => {
  const [state, dispatch] = useReducer(stateReducers, initialState);
  const {
    cursor,
    linesOfText = null,
    normalizedKeyMatrix = null,
    mean = null,
  } = state;

  const handleKeypress = (payload) =>
    dispatch({ type: actionTypes.main.handleKeypress, payload });

  useEffect(() => {
    const loadedStringState = localStorage.getItem(LOCAL_STORAGE_KEY);
    let loadedState = {};
    if (loadedStringState !== null) {
      loadedState = JSON.parse(loadedStringState);
      if (loadedState.version === 1 && Array.isArray(loadedState.keyMatrix)) {
        loadedState.keyMatrix = loadedState.keyMatrix.map((row) =>
          row.map((cell) =>
            cell === null ? null : Math.max(0, Math.min(5000, cell))
          )
        );
      } else {
        loadedState = {};
      }
    }
    const newMlKeyboardWorker = new MlKeyboardWorker();
    newMlKeyboardWorker.onmessage = ({ data: action }) => dispatch(action);
    dispatch({
      type: actionTypes.main.setState,
      payload: { ...loadedState, mlKeyboardWorker: newMlKeyboardWorker },
    });
    return () => {
      newMlKeyboardWorker.terminate();
    };
  }, []);

  return { linesOfText, normalizedKeyMatrix, handleKeypress, mean, cursor };
};
