const characters = Array.from("\0 abcdefghijklmnopqrstuvwxyz");
const mapCharToInt = Object.fromEntries(
  characters.map((char, index) => [char, index])
);
const vocabSize = characters.length;

const int2Char = (int) => characters[int];
const char2Int = (char) => mapCharToInt[char];

const randomInt = Math.floor(Math.random() * vocabSize);

console.assert(char2Int(int2Char(randomInt)) === randomInt);

const getRandomKey = () => {
  const int = Math.floor(Math.random() * vocabSize);
  return int === 0 || int === 1 ? getRandomKey() : int2Char(int);
};

const spaceProbability = new Array(16).fill(null).map((_, index) => {
  const k = 0.64766;
  const x0 = 6.18632;
  return Math.exp(k * (index - x0));
});

const calculateSpaceProbability = (sequenceLength) =>
  spaceProbability[sequenceLength] ||
  spaceProbability[spaceProbability.length - 1];

/*
const INV_EMA_WEIGHT = 2 / (1 + Math.sqrt(5));
const EMA_WEIGHT = 1 - INV_EMA_WEIGHT; // 1 - 0.618 = 0.382
*/

const INV_EMA_WEIGHT = 0.9;
const EMA_WEIGHT = 1 - INV_EMA_WEIGHT;

const calcEma = (x0, x1) =>
  x0 !== null ? x0 * INV_EMA_WEIGHT + x1 * EMA_WEIGHT : x1;

const timestamper = () =>
  typeof performance !== "undefined" ? performance.now() : Date.now();

class Key {
  constructor(charInt, time = timestamper()) {
    this.charInt = charInt || 1;
    this.timeStamp = time;
  }
}

class Line {
  constructor(keys, time = timestamper()) {
    this.time = time;
    this.keys = keys;
  }
}

// 12e3 [(ms·w)/(ch·min)] = 1000 [ms/s] * 60 [s/min] / 5 [ch/w]
// duration is in ms/ch, so 12e3 / duration has units of w/min
const durationToWPM = (duration) => (Boolean(duration) ? 12e3 / duration : 0);

module.exports = {
  characters,
  int2Char,
  char2Int,
  vocabSize,
  getRandomKey,
  calculateSpaceProbability,
  calcEma,
  Key,
  Line,
  durationToWPM,
  timestamper,
};
