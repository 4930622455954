import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { char2Int, int2Char } from "./utils";

const spaceInt = char2Int(" ");
const mapKeyToAlt = { [spaceInt]: "·", 0: " " };

const Canvas = styled.canvas`
  width: 18rem;
  height: 18rem;
`;

export const BigramMosaic = ({ bigrams = null }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (canvasRef === null || bigrams === null) return;
    const { current: canvas } = canvasRef;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.font = "0.5rem monospace";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.globalAlpha = 0.854;
    bigrams.forEach((row, rowIndex) =>
      row.forEach((bigram, colIndex) => {
        ctx.fillStyle = "#888";
        if (colIndex === 0) {
          ctx.fillText(
            mapKeyToAlt[rowIndex] || int2Char(rowIndex),
            16 * rowIndex + 8,
            8
          );
          return;
        }
        if (rowIndex === 0) {
          ctx.fillText(
            mapKeyToAlt[colIndex] || int2Char(colIndex),
            8,
            16 * colIndex + 8
          );
          return;
        }
        ctx.fillStyle = `hsl(${Math.floor(180 / (1 + bigram))}, 94.4%, 61.8%)`;
        ctx.fillRect(16 * colIndex, 16 * rowIndex, 16, 16);
      })
    );
  }, [canvasRef, bigrams]);
  return <Canvas height="448" width="448" ref={canvasRef} />;
};
