import { Key, Line, timestamper, vocabSize } from "../utils";

const generateEmptyLine = () => {
  const keys = new Array(34)
    .fill(null)
    .map((_, index) => new Key(0, timestamper() + index));
  return new Line(keys);
};

const linesOfText = new Array(2).fill(null).map(generateEmptyLine);
const keyMatrix = new Array(vocabSize)
  .fill(null)
  .map(() => new Array(vocabSize).fill(null));

export const initialState = {
  linesOfText,
  keyMatrix,
  cursor: 0,
  prevCharInt: null,
  prevTime: null,
  version: 1,
};
