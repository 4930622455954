import actionTypes from "../ml-keyboard-action-types.json";
import { generateReducers } from "../generate-reducers";
import { calcEma, char2Int, Line, timestamper } from "../utils";

export const LOCAL_STORAGE_KEY = "keyMatrix";
const MAX_DURATION = 5e3;

const saveState = (state) => {
  if (state === null) return;
  const { keyMatrix, normalizedKeyMatrix, mean, version } = state;
  localStorage.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify({ keyMatrix, normalizedKeyMatrix, mean, version })
  );
};

const setState = (state, newState) => {
  if (newState === null) return state;
  return { ...state, ...newState };
};

const handleKeypress = (state, char) => {
  const {
    linesOfText,
    cursor,
    prevTime,
    prevCharInt,
    mlKeyboardWorker,
    keyMatrix,
  } = state;
  if (linesOfText.length < 3) return state;
  const keypressTime = timestamper();
  const charInt = char2Int(char);
  const requiredCharInt = linesOfText[2].keys[cursor].charInt;
  if (charInt !== requiredCharInt) return state;
  const duration = keypressTime - prevTime;
  if (prevTime !== null && prevCharInt !== null && duration < MAX_DURATION) {
    const {
      [prevCharInt]: { [charInt]: prevTime = null },
    } = keyMatrix;
    keyMatrix[prevCharInt][charInt] = calcEma(prevTime, duration);
  }
  const nextCursor = cursor + 1;
  if (nextCursor === 34) {
    linesOfText.shift();
    if (linesOfText.length < 5) {
      mlKeyboardWorker.postMessage({
        type: actionTypes.worker.getNextLine,
        payload: keyMatrix,
      });
    }
    return {
      ...state,
      linesOfText,
      cursor: 0,
      prevCharInt: null,
      prevTime: null,
      keyMatrix,
    };
  }
  return {
    ...state,
    linesOfText,
    cursor: nextCursor,
    prevCharInt: charInt,
    prevTime: keypressTime,
    keyMatrix,
  };
};

const handleNextLine = (state, newState) => {
  const { linesOfText } = state;
  const { nextLine, ...restOfNewState } = newState;
  linesOfText.push(new Line(nextLine));
  if (linesOfText.length < 5) {
    const { mlKeyboardWorker, keyMatrix } = state;
    mlKeyboardWorker.postMessage({
      type: actionTypes.worker.getNextLine,
      payload: keyMatrix,
    });
  }
  const nextState = {
    ...state,
    ...restOfNewState,
    linesOfText,
  };
  saveState(nextState);
  return nextState;
};
const workerReady = (state) => {
  const { mlKeyboardWorker, keyMatrix, linesOfText } = state;
  if (linesOfText.length < 5) {
    mlKeyboardWorker.postMessage({
      type: actionTypes.worker.getNextLine,
      payload: keyMatrix,
    });
  }
  return state;
};
export const stateReducers = generateReducers({
  [actionTypes.main.setState]: setState,
  [actionTypes.main.handleKeypress]: handleKeypress,
  [actionTypes.main.handleNextLine]: handleNextLine,
  [actionTypes.main.workerReady]: workerReady,
});
